import { Link } from 'react-router-dom';
import '../styles/About.css';
import ArrowIcon from './ArrowIcon';

const About = () => {
  return (
    <div className="about">
	  <div className="about_para">
		<p className="about_title">About Me</p>
		<p className="about_content">Hello, I'm Joe, a Full-stack Developer with AI and Big Data Expertise, with experience in large language models, ETL, and
enterprise cloud solutions (IaaS/PaaS/SaaS) at Ericsson. I have a strong background in software design, performance
optimization, and project refactoring.
I hold a Master’s degree in Computer Science with distinction from the University of Adelaide and currently
work on an AI conversation project. Before that, I served as a full-stack and data engineer at PingAn Group, one of China’s
leading organizations, ranked 33rd in the Fortune Global 500.</p>
		
		
		
		

	  </div>
	  <div className="about_para">
	    <p className="about_title">Experience & Education</p>
		<div className="about_content">
			<ul>
			  <li>
				<span className ="date">Jun 2024 - Now</span>
				<span className ="institution">Ericsson</span>
				<span className ="position">AI Engineer, Full-stack Engineer</span>
			  </li>
			  <li>
				<span className ="date">July 2022 - July 2024</span>
				<span className ="institution">The University of Adelaide</span>
				<span className ="position">Master of Computer Science</span>
			  </li>
			  <li>
				<span className ="date">Aug 2019 - Jun 2022</span>
				<span className ="institution">PingAn</span>
				<span className ="position">Senior Software Engineer, Data Engineer</span>
			  </li>
			  <li>
				<span className ="date">Jan 2018 - Aug 2029</span>
				<span className ="institution">SF express</span>
				<span className ="position">Java Software Engineer</span>
			  </li>
			  <li>
				<span className ="date">Oct 2014 - Jan 2018</span>
				<span className ="institution">ZhongChengPinYe</span>
				<span className ="position">Full-stack Developer</span>
			  </li>
			</ul>

		</div>
	  </div>
	  <div className="about_para">
	    <p className="about_title">Personal Projects</p>
		<div className="about_content">
			<Link to="/personal_pro">
				Mini Games · Tank Online · Material Trans · A+STORE. [Click for more details]
			</Link>
			<div className="about_content_inline">
				<ArrowIcon/>
			</div>
		</div>
	  </div>
	  <div className="about_para">
	    <p className="about_title">Tech Stack</p>
		<div className="about_content">
			<Link to="/tech_stack">
				Java · Python · C · C++ · Full-stack · AI · MiningBigData. [Click for more details]			
			</Link>
			<div className="about_content_inline">
				<ArrowIcon/>
			</div>
		</div>
	  </div>
    </div>
  );
}

export default About;
